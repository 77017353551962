import styled from "styled-components/macro";

export const ErrorContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	flex-direction: column;
`;
export const ErrorHeader = styled.h2`
	font-size: 2rem;
	color: #ff9c41;
`;
