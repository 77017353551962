import React from "react";
import { Link } from "gatsby";
import { ErrorHeader, ErrorContainer } from "./styles";

const NotFound = () => {
	return (
		<ErrorContainer>
			<ErrorHeader>Strona nie istnieje: 404</ErrorHeader>
			<Link to="/">Wróć do strony głównej</Link>
		</ErrorContainer>
	);
};

export default NotFound;
